<template>
    <div>
        <div class="mb-4">
            <h4 class="font-weight-bold">STEP 1: WAJIB RETRIBUSI DAERAH</h4>
            <em>
                Wajib Retribusi Daerah adalah <b>Perorangan</b> atau <b> Badan Usaha </b> yang memiliki kewajiban retribusi di daerah Provinsi Sumatera Utara.
            </em>
        </div>
        <div>
            <b-form
                @submit.prevent="editMode ? update($route.params.id) : store()"
                @keydown="form.onKeydown($event)"
                autocomplete="off"
            >
                <div class="form-group row mb-1">
                    <label class="col-md-3 col-form-label font-weight-bold"
                        >Jenis
                        <span class="text-danger">*</span>
                    </label>
                    <div class="col-md-4">
                        <v-select
                            :options="['Perorangan', 'Badan']"
                            v-model="form.jenis"
                            @input="inputJenis(form.jenis)"
                            placeholder="Pilih Jenis"
                            class="bg-white"
                            :disabled="editMode ? disableEdit : disableForm"
                        >
                        </v-select>
                        <em
                            v-if="form.errors.has('jenis')"
                            class="form-text text-danger"
                            >{{ errors.jenis[0] }}</em
                        >
                    </div>
                </div>
                <div class="form-group row mb-1" v-show="disableBentuk">
                    <label class="col-md-3 col-form-label font-weight-bold"
                        >Bentuk Badan
                        <span class="text-danger">*</span>
                    </label>
                    <div class="col-md-4">
                        <v-select
                            label="nama"
                            :options="bentukBadanOptions"
                            :reduce="(nama) => nama.id"
                            v-model="form.bentuk_badan_id"
                            placeholder="Pilih Bentuk Badan"
                            class="bg-white"
                            :disabled="disableForm"
                            @input="checkPemerintahan(form.bentuk_badan_id)"
                        >
                        </v-select>
                        <em
                            v-if="form.errors.has('bentuk_badan_id')"
                            class="form-text text-danger"
                            >{{ errors.bentuk_badan_id[0] }}</em
                        >
                    </div>
                </div>
                <div class="form-group row mb-1">
                    <label class="col-md-3 col-form-label font-weight-bold"
                        ><span v-if="disableBentuk">
                            NIB
                        </span>
                        <span v-else>
                            NIK
                        </span>
                        <span class="text-danger" v-show="disableOP">*</span>
                    </label>
                    <div class="col-md-6">
                        <input
                            type="text"
                            :class="{
                                'form-control': true,
                                'is-invalid': form.errors.has('no_induk'),
                            }"
                            v-model="form.no_induk"
                            :placeholder="disableBentuk ? 'Masukkan NIB': 'Masukkan NIK'"
                            :disabled="disableForm"
                        />
                        <em
                            v-if="form.errors.has('no_induk')"
                            class="form-text text-danger"
                            >{{ errors.no_induk[0] }}</em
                        >
                        <div class="col-md-12">
                            <div class="row font-weight-bold text-danger">Perhatian:</div>
                            <ol class="p-0" style="line-height: 1.2em">
                                <li>
                                    Jika jenis WR (Wajib Retribusi) Perorangan, maka masukkan NIK (Nomor Induk Kependudukan).
                                </li>
                                <li>
                                   Jika jenis WR (Wajib Retribusi) Badan, maka masukkan NIB (Nomor Induk Berusaha).
                                </li>
                            </ol>
                        </div>
                    </div>
                </div>
                <div class="form-group row mb-1">
                    <label class="col-md-3 col-form-label font-weight-bold"
                        >Nama Wajib Retribusi
                        <span class="text-danger">*</span>
                    </label>
                    <div class="col-md-6">
                        <input
                            type="text"
                            :class="{
                                'form-control': true,
                                'is-invalid': form.errors.has('nama'),
                            }"
                            v-model="form.nama"
                            placeholder="Masukkan Nama Wajib Retribusi"
                            style="text-transform: uppercase;"
                            :disabled="disableForm"
                        />
                        <em
                            v-if="form.errors.has('nama')"
                            class="form-text text-danger"
                            >{{ errors.nama[0] }}</em
                        >
                        <div class="col-md-12">
                            <div class="row font-weight-bold text-danger">Perhatian:</div>
                            <ol class="p-0" style="line-height: 1.2em">
                                <li>
                                    Masukkan hanya nama badan usaha (contoh: PT. Indonesia Sejahtera Bersatu menjadi Indonesia Sejahtera Bersatu).
                                </li>
                                <li>
                                    Masukkan ejaan sesuai dengan NIB (jika Badan Usaha) atau NIK (jika Perorangan).
                                </li>
                            </ol>
                        </div>
                    </div>
                </div>
                <div class="py-3">
                    <h4>KONTAK & ALAMAT</h4>
                </div>
                <div class="form-group row mb-1">
                    <label class="col-md-2 col-form-label font-weight-bold"
                        >Nomor HP
                        <span class="text-danger">*</span>
                    </label>
                    <div class="col-md-6">
                        <input
                            type="text"
                            :class="{
                                'form-control': true,
                                'is-invalid': form.errors.has('nomp'),
                            }"
                            v-model="form.nomp"
                            placeholder="Masukkan Nomor Handphone"
                            :disabled="disableForm"
                        />
                        <em
                            v-if="form.errors.has('nomp')"
                            class="form-text text-danger"
                            >{{ errors.nomp[0] }}</em
                        >
                    </div>
                </div>
                <div class="form-group row mb-1">
                    <label class="col-md-2 col-form-label font-weight-bold"
                        >Nomor Telepon
                        <span class="text-danger">*</span>
                    </label>
                    <div class="col-md-6">
                        <input
                            type="text"
                            :class="{
                                'form-control': true,
                                'is-invalid': form.errors.has('notelepon'),
                            }"
                            v-model="form.notelepon"
                            placeholder="Masukkan Nomor Telepon"
                            :disabled="disableForm"
                        />
                        <em
                            v-if="form.errors.has('notelepon')"
                            class="form-text text-danger"
                            >{{ errors.notelepon[0] }}</em
                        >
                    </div>
                </div>
                <div class="form-group row mb-1">
                    <label class="col-md-2 col-form-label font-weight-bold"
                        >Email
                        <span class="text-danger">*</span>
                    </label>
                    <div class="col-md-6">
                        <input
                            type="email"
                            :class="{
                                'form-control': true,
                                'is-invalid': form.errors.has('email'),
                            }"
                            v-model="form.email"
                            placeholder="Masukkan Alamat Email"
                            :disabled="disableForm"
                        />
                        <em
                            v-if="form.errors.has('email')"
                            class="form-text text-danger"
                            >{{ errors.email[0] }}</em
                        >
                    </div>
                </div>
                <div class="form-group row mb-1">
                    <label class="col-md-2 col-form-label font-weight-bold"
                        >Alamat
                    </label>
                    <div class="col-md-6">
                        <input
                            type="text"
                            :class="{
                                'form-control': true,
                                'is-invalid': form.errors.has('alamat'),
                            }"
                            v-model="form.alamat"
                            placeholder="Masukkan Alamat"
                            :disabled="disableForm"
                        />
                        <em
                            v-if="form.errors.has('alamat')"
                            class="form-text text-danger"
                            >{{ errors.alamat[0] }}</em
                        >
                    </div>
                </div>
                <div class="form-group row mb-1">
                    <label class="col-md-2 col-form-label font-weight-bold"
                        >Provinsi
                        <span class="text-danger">*</span>
                    </label>
                    <div class="col-md-4">
                        <v-select
                            label="nama"
                            :options="provinsiOptions"
                            :reduce="(nama) => nama.id"
                            v-model="input.provinsi"
                            @input="onProvinsi(input.provinsi)"
                            placeholder="Pilih Provinsi"
                            :disabled="disableForm"
                        >
                        </v-select>
                        <em
                            v-if="form.errors.has('wilayah_kelurahan_id')"
                            class="form-text text-danger"
                            >Isian Provinsi Wajib Retribusi harus diisi.</em
                        >
                    </div>
                </div>
                <div class="form-group row mb-1">
                    <label class="col-md-2 col-form-label font-weight-bold"
                        >Kota/Kabupaten
                        <span class="text-danger">*</span>
                    </label>
                    <div class="col-md-4">
                        <v-select
                            label="nama"
                            :options="kotaOptions"
                            v-model="input.kabkota"
                            :reduce="(nama) => nama.id"
                            @input="onKabKota(input.kabkota)"
                            placeholder="Pilih Kabupaten/Kota"
                            class="bg-white"
                            :disabled="disableForm"
                        ></v-select>
                        <em
                            v-if="form.errors.has('wilayah_kelurahan_id')"
                            class="form-text text-danger"
                            >Isian Kab./Kota Wajib Retribusi harus diisi.</em
                        >
                    </div>
                </div>
                <div class="form-group row mb-1">
                    <label class="col-md-2 col-form-label font-weight-bold"
                        >Kecamatan
                        <span class="text-danger">*</span>
                    </label>
                    <div class="col-md-4">
                        <v-select
                            label="nama"
                            :options="kecamatanOptions"
                            :reduce="(nama) => nama.id"
                            v-model="input.kecamatan"
                            @input="onKecamatan(input.kecamatan)"
                            placeholder="Pilih Kecamatan"
                            :disabled="disableForm"
                        ></v-select>
                        <em
                            v-if="form.errors.has('wilayah_kelurahan_id')"
                            class="form-text text-danger"
                            >Isian Kecamatan Wajib Retribusi harus diisi.</em
                        >
                    </div>
                </div>
                <div class="form-group row mb-1">
                    <label class="col-md-2 col-form-label font-weight-bold"
                        >Kelurahan
                        <span class="text-danger">*</span>
                    </label>
                    <div class="col-md-4">
                        <v-select
                            label="nama"
                            :options="kelurahanOptions"
                            :reduce="(nama) => nama.id"
                            v-model="form.wilayah_kelurahan_id"
                            placeholder="Pilih Kelurahan"
                            class="bg-white"
                            :disabled="disableForm"
                        ></v-select>
                        <em
                            v-if="form.errors.has('wilayah_kelurahan_id')"
                            class="form-text text-danger"
                            >{{ errors.wilayah_kelurahan_id[0] }}</em
                        >
                    </div>
                </div>
                <div class="form-group row mb-1">
                    <label class="col-md-2 col-form-label font-weight-bold"
                        >Kodepos
                        <span class="text-danger">*</span>
                    </label>
                    <div class="col-md-4">
                        <input
                            type="text"
                            :class="{
                                'form-control': true,
                                'is-invalid': form.errors.has('kodepos'),
                            }"
                            v-model="form.kodepos"
                            placeholder="Masukkan Kodepos"
                            :disabled="disableForm"
                        />
                        <em
                            v-if="form.errors.has('kodepos')"
                            class="form-text text-danger"
                            >{{ errors.kodepos[0] }}</em
                        >
                    </div>
                </div>
                <hr />
                <div>
                    <span>
                        <b-button
                            variant="primary"
                            type="submit"
                            class="px-4"
                        >
                            <span>
                                <span v-if="isLoading" class="px-1">
                                    <b-spinner
                                        variant="primary"
                                        small
                                    ></b-spinner>
                                </span>
                                <span v-else class="px-1">
                                    <i class="fa fa-save" v-if="!editMode"></i>
                                    <i class="fa fa-edit" v-else></i>
                                </span>
                                <span v-if="!editMode">
                                    Simpan
                                </span>
                                <span v-else>
                                    Edit
                                </span>
                            </span>
                        </b-button>
                        <span class="px-2">
                            <b-button variant="secondary" v-if="!editMode">
                                <i class="fa fa-redo-alt"></i> Reset
                            </b-button>
                        </span>
                    </span>
                    <span class="float-right">
                        <div>
                            <b-button variant="success" @click="editMode ? editNextButton() : nextButton()" v-if="disableNext">
                                Selanjutnya <i class="fa fa-arrow-circle-right"></i>
                            </b-button>
                        </div>
                    </span>
                </div>
            </b-form>
        </div>
    </div>
</template>

<script>
/* eslint-disable */
// eslint-disable-next-line
// eslint-disable vue/no-unused-vars
// eslint-disable-line no-unused-vars
import axios from "axios";
import { mapState } from 'vuex';
import hapusStorage from '@/helper/hapusLocalStore'

export default {
    mixins: [hapusStorage],
    props: ["detailProfil"],
    data() {
        return {
            form: new Form({
                bentuk_badan_id: "",
                jenis: "",
                nama: "",
                nomp: "",
                no_induk: "",
                notelepon: "",
                email: "",
                alamat: "",
                wilayah_kelurahan_id: null,
                kodepos: "",
            }),
            input: {
                provinsi: "",
                kabkota: "",
                kecamatan: "",
            },
            bentukBadanOptions: [],
            provinsiOptions: [],
            kotaOptions: [],
            kecamatanOptions: [],
            kelurahanOptions: [],
            isLoading: false,
            errors: [],
            disableBentuk: false,
            user: JSON.parse(localStorage.getItem("user")),
            detail: [],
            disableForm: false,
            disableEdit: false,
            editMode: JSON.parse(localStorage.getItem("editMode")),
            disableNext: false,
            disableOP: true
        };
    },
    mounted() {
        this.getBentukBadan();
        this.getProvinsi();
        if (JSON.parse(localStorage.getItem("editMode")) === true) {
            this.disableEdit = true
            this.detailNpwpd(this.$route.params.id);
        }
    },
    computed: {
        ...mapState([
            "cekNpwrd",
        ])
    },
    methods: {
        // when npwpd id exist
        detailNpwpd(id) {
            axios
                .get("/api/wajib-retribusi/npwrd/" + id)
                .then((response) => {
                    this.detail = response.data.data;
                    this.form.fill(this.detail);
                    if (this.form.jenis <= 40) {
                        this.form.jenis = "Badan";
                        this.disableBentuk = true;
                        this.disableJenis = true;
                        this.disableNext = true
                        if (this.detail.bentuk_badan_id !== 8) {
                            this.disableOP = true
                        } else {
                            this.disableOP = false
                        }
                    } else if (this.form.jenis >= 41) {
                        this.form.jenis = "Perorangan";
                        this.disableBentuk = false;
                        this.disableJenis = true;
                        this.disableNext = false
                        this.$store.dispatch("Pendaftaran/isEventDisable2", true)
                        this.$store.dispatch("Pendaftaran/isEventDisable3", true)
                    }
                    // this.form.wilayah_kelurahan_id = parseInt(this.detail.wilayah_kelurahan_id)
                    this.input.provinsi = this.detail.wilayah_kelurahan.wilayah_kecamatan.wilayah_kabkota.wilayah_provinsi_id
                    this.getKabupaten(this.input.provinsi)
                    this.input.kabkota = this.detail.wilayah_kelurahan.wilayah_kecamatan.wilayah_kabkota_id
                    this.getKecamatan(this.input.kabkota)
                    this.input.kecamatan = this.detail.wilayah_kelurahan.wilayah_kecamatan_id
                    this.getKelurahan(this.input.kecamatan)
                    this.form.wilayah_kelurahan_id = parseInt(this.form.wilayah_kelurahan_id)
                    // console.log(this.detail);
                })
                .catch((error) => {
                    if (error.response.status === 401) {
                        // hapus Storage mixins
                        this.clearAll()
                    }
                });
        },
        // store data
        store() {
            this.isLoading = true;
            this.form.nama = this.form.nama.toUpperCase()
            if (this.form.jenis === "Perorangan") {
                this.form.bentuk_badan_id = 2
            }
            this.form
                .post("/api/wajib-retribusi/npwrd")
                .then((response) => {
                    if (this.form.successful) {
                        if (this.form.jenis === "Perorangan") {
                            this.disableBentuk = false;
                            this.reset()
                            this.$store.dispatch("Pendaftaran/isEventDisable", false)
                            this.$store.dispatch("Pendaftaran/isEventDisable2", false)
                            this.$store.dispatch("Pendaftaran/isEventDisable3", false)
                            this.$swal({
                                icon: "success",
                                title: "Berhasil Menambah Data Wajib Retribusi Perorangan.",
                                type: "success",
                                confirmButtonClass: "btn btn-info",
                                showConfirmButton: true,
                                confirmButtonClass: "btn btn-primary",
                                confirmButtonText: "OKE",
                                showCloseButton: true,
                            });
                        } else {
                            this.disableBentuk = true;
                            this.disableForm = true
                            this.disableNext = true
                            this.$store.dispatch("Pendaftaran/isEventDisable", true)
                            this.$swal({
                                icon: "success",
                                title: "Berhasil Menambah Data Wajib Retribusi Badan Usaha.",
                                html: "Silahkan klik 'Selanjutnya' untuk menambah data <b>Pimpinan</b> dan <b>Penanggung Jawab</b>",
                                type: "success",
                                confirmButtonClass: "btn btn-info",
                                showConfirmButton: true,
                                confirmButtonClass: "btn btn-primary",
                                confirmButtonText: "Selanjutnya <i class='fa fa-arrow-circle-right'></i>",
                                showCloseButton: true,
                            }).then(result => {
                                if (result.isConfirmed) {
                                    this.nextButton()
                                }
                            });
                        }
                        this.isLoading = false
                        localStorage.setItem('npwrd_id', response.data.data.id)

                    }
                })
                .catch((error) => {
                    this.isLoading = false;
                    this.disableForm = false
                    this.disableNext = false
                    if (error.response.status === 422) {
                        this.errors = error.response.data;
                        this.message = error.response.data.message;
                    } else if (error.response.status === 401) {
                        // hapus Storage mixins
                        this.clearAll()
                    } else {
                        console.log(error.response.data.message);
                        this.$swal({
                            icon: "error",
                            title: "Terjadi Masalah",
                            html:
                                "Silahkan hubungi bagian maintenance. <br> ERROR: " +
                                error.response.data.message,
                            confirmButtonClass: "btn btn-info",
                            showConfirmButton: true,
                            showCloseButton: true,
                            confirmButtonText: "OKE",
                            confirmButtonClass: "btn btn-primary",
                            timer: 5000,
                        });
                    }
                    console.log(error);
                });
        },
        // store data
        update(id) {
            this.isLoading = true;
            this.form.nama = this.form.nama.toUpperCase()
            if (this.form.jenis === "Perorangan") {
                this.form.bentuk_badan_id = 2
            } else {
                this.form.bentuk_badan_id = this.form.bentuk_badan_id
            }
            this.form
                .put("/api/wajib-retribusi/npwrd/" + id)
                .then((response) => {
                    if (this.form.successful) {
                        if (this.form.jenis === "Perorangan") {
                            this.editMode = true
                            this.$store.dispatch("Pendaftaran/isEventDisable", false)
                            this.$swal({
                                icon: "success",
                                title: "Data Wajib Retribusi Perorangan Berhasil Dirubah.",
                                type: "success",
                                confirmButtonClass: "btn btn-info",
                                showConfirmButton: true,
                                confirmButtonClass: "btn btn-primary",
                                confirmButtonText: "OKE",
                                showCloseButton: true,
                            });
                        } else {
                            this.disableForm = true
                            this.$store.dispatch("Pendaftaran/isEventDisable", true)
                            this.$swal({
                                icon: "success",
                                title: "Data Wajib Retribusi Badan Usaha Berhasil Dirubah.",
                                html: "Silahkan klik 'Selanjutnya' untuk edit data <b>Pimpinan</b> dan <b>Penanggung Jawab</b>",
                                type: "success",
                                confirmButtonClass: "btn btn-info",
                                showConfirmButton: true,
                                confirmButtonClass: "btn btn-primary",
                                confirmButtonText: "Selanjutnya <i class='fa fa-arrow-circle-right'></i>",
                                showCloseButton: true,
                            }).then(result => {
                                if (result.isConfirmed) {
                                    this.editNextButton()
                                }
                            });
                        }
                        this.isLoading = false
                    }
                })
                .catch((error) => {
                    this.isLoading = false;
                    this.disableForm = false
                    if (error.response.status === 422) {
                        this.errors = error.response.data;
                        this.message = error.response.data.message;
                    } else if (error.response.status === 401) {
                        // hapus Storage mixins
                        this.clearAll()
                    } else {
                        console.log(error.response.data.message);
                        this.$swal({
                            icon: "error",
                            title: "Terjadi Masalah",
                            html:
                                "Silahkan hubungi bagian maintenance. <br> ERROR: " +
                                error.response.data.message,
                            confirmButtonClass: "btn btn-info",
                            showConfirmButton: true,
                            showCloseButton: true,
                            confirmButtonText: "OKE",
                            confirmButtonClass: "btn btn-primary",
                            timer: 5000,
                        });
                    }
                    console.log(error);
                });
        },
        // bentuk badan
        getBentukBadan() {
            axios
                .get("/api/wajib-retribusi/bentuk-badan/getoption")
                .then((response) => {
                    this.bentukBadanOptions = response.data.data;
                })
                .catch((error) => {
                    console.log(error);
                    if (error.response.error == 401) {
                        // hapus Storage mixins
                        this.clearAll()
                    }
                });
        },
        inputJenis(value) {
            if (value === "Perorangan") {
                this.$store.dispatch("Pendaftaran/isEventDisable2", true);
                this.$store.dispatch("Pendaftaran/isEventDisable3", true);
                this.disableBentuk = false;
            } else {
                this.$store.dispatch("Pendaftaran/isEventDisable2", false);
                this.$store.dispatch("Pendaftaran/isEventDisable3", false);
                this.disableBentuk = true;
            }
        },
        //Wilayah
        // saat provinsi di input
        onProvinsi(id) {
            if (this.input.provinsi === null) {
                this.kotaOptions = [];
                this.input.kota = null;
                this.kecamatanOptions = [];
                this.input.kecamatan = null;
            } else {
                this.getKabupaten(id);
            }
        },
        // saat kab./kota di input
        onKabKota(id) {
            if (this.input.kota === null) {
                this.kecamatanOptions = [];
                this.input.kecamatan = null;
            } else {
                this.getKecamatan(id);
            }
        },

        // saat kab./kota di input
        onKecamatan(id) {
            if (this.input.kecamatan === null) {
                this.kecamatanOptions = [];
                this.input.kecamatan = null;
            } else {
                this.getKelurahan(id);
            }
        },

        // Provinsi
        getProvinsi() {
            axios
                .get("/api/data-induk/wilayah-indonesia/provinsi/getoption")
                .then((response) => {
                    this.provinsiOptions = response.data.data;
                    /* console.log(this.provinsiOptions) */
                })
                .catch((error) => {
                    if (error.response.status === 401) {
                        // hapus Storage mixins
                        this.clearAll()
                    }
                });
        },
        // Kabupaten
        getKabupaten(id) {
            axios
                .get(
                    "/api/data-induk/wilayah-indonesia/kabkota/getoption/provinsi-" +
                        id
                )
                .then((response) => {
                    /* console.log(response.data.data) */
                    this.kotaOptions = response.data.data;
                })
                .catch((error) => {
                    if (error.response.status === 401) {
                        // hapus Storage mixins
                        this.clearAll()
                    }
                });
        },
        // Kecamatan
        getKecamatan(id) {
            axios
                .get(
                    "/api/data-induk/wilayah-indonesia/kecamatan/getoption/kabkota-" +
                        id
                )
                .then((response) => {
                    /*  console.log(response.data.data) */
                    this.kecamatanOptions = response.data.data;
                })
                .catch((error) => {
                    if (error.response.status === 401) {
                        // hapus Storage mixins
                        this.clearAll()
                    }
                });
        },

        // Kelurahan
        getKelurahan(id) {
            axios
                .get(
                    "/api/data-induk/wilayah-indonesia/kelurahan/getoption/kecamatan-" +
                        id
                )
                .then((response) => {
                    /* console.log(response.data.data) */
                    this.kelurahanOptions = response.data.data;
                })
                .catch((error) => {
                    if (error.response.status === 401) {
                        // hapus Storage mixins
                        this.clearAll()
                    }
                });
        },

        // reset
        reset() {
            this.form.reset();
            this.form.clear();
            this.input.provinsi = null;
            this.input.kabkota = null;
            this.input.kecamatan = null;
            this.input.kabupaten = null;
        },
        nextButton () {
            this.$router.push({ name: "DaftarPimpinan"})
            this.$store.dispatch("cekNpwrd/npwrdData", this.detail.id)
        },
        editNextButton () {
            if (this.detail.pimpinan == null) {
                this.$router.push({ name: 'DaftarPimpinan' })
                this.$store.dispatch("cekNpwrd/npwrdData", this.detail.id)
            } else {
                console.log(this.detail.pimpinan.id)
                this.$router.push({ name: "EditPimpinan", params: {id: this.detail.pimpinan.id }})
                this.$store.dispatch("cekNpwrd/npwrdData", this.detail.id)
            }
        },
        checkPemerintahan (id) {
            if (id !== 8) {
                this.disableOP = true
            } else {
                this.disableOP = false
            }
        }
    },
};
</script>
